<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <div class="container-fluid">
          <!-- Default box -->
          <section class="content-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="mb-0">Solicitudes Vehículos</h5>
                </div>
                <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                      <router-link to="/">Home</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/Hse">Hse</router-link>
                    </li>
                    <li class="breadcrumb-item active">
                      Solicitudes Vehículos
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section class="content">
            <div class="container-fluid">
              <div class="card">
                <div class="card-header pt-2 pb-2">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="btn-group float-right">
                        <button
                          type="button"
                          class="btn btn-success"
                          data-toggle="modal"
                          data-target="#modal_form_solicitud_vehiculos"
                          v-if="
                            $store.getters.can(
                              'hse.solicitudesVehiculos.export'
                            )
                          "
                        >
                          <i class="far fa-file-excel"></i>
                        </button>
                        <button
                          type="button"
                          class="btn bg-navy"
                          @click="getIndex()"
                        >
                          <i class="fas fa-sync-alt"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-md bg-danger"
                          @click="back()"
                        >
                          <i class="fas fa-broom"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body pb-0">
                  <div class="row justify-content-center">
                    <div class="form-group col-md-2">
                      <label>N° Solicitud</label>
                      <input
                        class="form-control form-control-sm"
                        type="number"
                        v-model="filters.solicitud_id"
                        @change="getIndex()"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label>Vehículo</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="filters.placa"
                        @change="getIndex()"
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label>Email</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="filters.email"
                        @input="getIndex()"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label>Clase Vehículo</label>
                      <v-select
                        class="form-control form-control-sm p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="clase_vehiculo"
                        placeholder="Clase Vehículo"
                        label="descripcion"
                        :options="listsForms.clases_vehiculos"
                        @input="selectClaseVehiculo()"
                      >
                      </v-select>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Modelo</label>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        v-model="filters.modelo"
                        @input="getIndex()"
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label>Empresa</label>
                      <v-select
                        class="form-control form-control-sm p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="empresa"
                        placeholder="Empresa"
                        label="descripcion"
                        :options="listsForms.empresas"
                        @input="selectEmpresa()"
                      >
                      </v-select>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Fecha Fin Tarjetón</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="filters.fecha_fin_tarjeton"
                        @input="getIndex()"
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label>Marca</label>
                      <v-select
                        class="form-control form-control-sm p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="marca"
                        placeholder="Marca"
                        label="descripcion"
                        :options="listsForms.marcas"
                        @input="selectMarca()"
                      >
                      </v-select>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Área</label>
                      <v-select
                        class="form-control form-control-sm p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="area"
                        placeholder="Area"
                        label="descripcion"
                        :options="listsForms.areas"
                        @input="selectArea()"
                      >
                      </v-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label>Sitio</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="filters.sitio"
                        @change="getIndex()"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label>Activo</label>
                      <v-select
                        class="form-control form-control-sm p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="activo"
                        placeholder="Activo"
                        label="descripcion"
                        :options="listsForms.activos"
                        @input="selectActivo()"
                      >
                      </v-select>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Operación</label>
                      <v-select
                        class="form-control form-control-sm p-0"
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="operacion"
                        placeholder="Operación"
                        label="descripcion"
                        :options="listsForms.operacion_vehiculos"
                        @input="selectOperacion()"
                      >
                      </v-select>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Estado</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filters.estado"
                        @change="getIndex()"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="estadoOpc in listsForms.estados"
                          :key="estadoOpc.numeracion"
                          :value="estadoOpc.numeracion"
                        >
                          {{ estadoOpc.descripcion }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Estado Inspección</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filters.estado_inspeccion"
                        @change="getIndex()"
                      >
                        <option value>Seleccione...</option>
                        <option
                          v-for="estadoInsp in listsForms.estadosInsp"
                          :key="estadoInsp.numeracion"
                          :value="estadoInsp.numeracion"
                        >
                          {{ estadoInsp.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <table
                    style="font-size: 0.85em"
                    class="table table-bordered table-striped table-hover table-sm text-nowrap"
                  >
                    <thead class="bg-dark">
                      <tr>
                        <th>N° Solicitud</th>
                        <th>Foto</th>
                        <th>Vehículo</th>
                        <th>Email</th>
                        <th class="col-2">Clase Vehículo</th>
                        <th>Modelo</th>
                        <th>Empresa</th>
                        <th>Fecha Fin Tarjetón</th>
                        <th>Fecha Vencimiento Inspección</th>
                        <th>Marca</th>
                        <th>Área</th>
                        <th>Sitio</th>
                        <th>Activo</th>
                        <th>Operación</th>
                        <th>Estado</th>
                        <th>Est. Insp.</th>
                        <th class="text-wrap">Documentos Verificados</th>
                        <th class="text-center">Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="solicitud in solicitudesVehiculos.data"
                        :key="solicitud.data"
                      >
                        <td class="text-center">{{ solicitud.id }}</td>
                        <td>
                          <a
                            v-if="
                              solicitud.link_foto ? solicitud.link_foto : null
                            "
                            :href="uri_docs + solicitud.link_foto"
                            target="_blank"
                          >
                            <img
                              :src="uri_docs + solicitud.link_foto"
                              alt="Vehiculo"
                              width="75"
                              class="rounded mx-auto d-block"
                            />
                          </a>
                        </td>
                        <td class="text-center">
                          {{ solicitud.placa }}
                        </td>
                        <td>{{ solicitud.email }}</td>
                        <td class="text-center">{{ solicitud.clase_vh }}</td>
                        <td class="text-center">{{ solicitud.modelo }}</td>
                        <td>{{ solicitud.Empresa }}</td>
                        <td
                          class="text-center"
                          v-if="solicitud.fecha_fin_tarjeton"
                        >
                          {{ solicitud.fecha_fin_tarjeton }}
                        </td>
                        <td class="text-center" v-else>
                          <span class="badge badge-danger">Sin Asignar</span>
                        </td>
                        <td class="text-center" v-if="solicitud.inspeccion_id">
                          {{
                            solicitud.inspeccion
                              ? solicitud.inspeccion.fecha_vencimiento
                              : ""
                          }}
                          {{
                            solicitud.inspeccion
                              ? solicitud.inspeccion.hora_vencimiento
                              : ""
                          }}
                        </td>
                        <td class="text-center" v-else>
                          <span class="badge badge-danger">Sin Inspección</span>
                        </td>
                        <td>{{ solicitud.Marca }}</td>
                        <td>{{ solicitud.Area }}</td>
                        <td>
                          {{ solicitud.sitios ? solicitud.sitios.nombre : "" }}
                        </td>
                        <td>{{ solicitud.Activo }}</td>
                        <td>{{ solicitud.Operacion }}</td>
                        <td class="text-center">
                          <span
                            class="badge"
                            :class="[
                              solicitud.estado == 1
                                ? 'badge-secondary'
                                : solicitud.estado == 2
                                ? 'badge-success'
                                : solicitud.estado == 3
                                ? 'badge-danger'
                                : solicitud.estado == 4
                                ? 'bg-olive'
                                : solicitud.estado == 5
                                ? 'badge-warning'
                                : solicitud.estado == 6
                                ? 'badge-info'
                                : solicitud.estado == 7
                                ? 'bg-navy'
                                : solicitud.estado == 8
                                ? 'bg-lime'
                                : '',
                            ]"
                            >{{ solicitud.Estado }}
                          </span>
                        </td>
                        <td class="text-center">
                          <div v-if="solicitud.inspeccion_id">
                            <span
                              v-if="
                                solicitud.inspeccion &&
                                  new Date(
                                    solicitud.inspeccion.fecha_vencimiento +
                                      'T' +
                                      solicitud.inspeccion.hora_vencimiento
                                  ) > new Date()
                              "
                              class="badge"
                              :class="[
                                solicitud.estado_inspeccion == 1
                                  ? 'badge-secondary'
                                  : solicitud.estado_inspeccion == 2
                                  ? 'badge-success'
                                  : solicitud.estado_inspeccion == 3
                                  ? 'badge-warning'
                                  : solicitud.estado_inspeccion == 4
                                  ? 'badge-danger'
                                  : solicitud.estado_inspeccion == 5
                                  ? 'bg-olive'
                                  : '',
                              ]"
                            >
                              {{ solicitud.estadoInspeccion }}
                            </span>
                            <span class="badge bg-danger" v-else> Vencida</span>
                          </div>
                        </td>
                        <td class="text-center">
                          <i
                            v-if="solicitud.estado == 6"
                            :class="
                              solicitud.documentos_files[0]
                                ? 'fas fa-times-circle text-danger'
                                : 'fas fa-check-circle text-green'
                            "
                          ></i>
                        </td>
                        <td class="text-center">
                          <div class="btn-group float-right">
                            <button
                              type="button"
                              class="btn btn-sm bg-purple"
                              v-if="
                                solicitud.estado != 3 &&
                                  (solicitud.estado_inspeccion == 1 ||
                                    solicitud.estado_inspeccion == null) &&
                                  $store.getters.can('hse.inspecciones.create')
                              "
                              @click="inspeccion(solicitud)"
                            >
                              <i class="fas fa-tasks"></i>
                            </button>
                            <button
                              v-if="
                                solicitud.estado == 2 &&
                                  $store.getters.can(
                                    'hse.solicitudesVehiculos.generarTarjeton'
                                  )
                              "
                              type="button"
                              class="btn btn-sm bg-primary"
                              @click="generarTarjeton(solicitud)"
                            >
                              <i class="fas fa-passport"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              @click="edit(solicitud, 1)"
                              v-if="
                                $store.getters.can(
                                  'hse.solicitudesVehiculos.edit'
                                )
                              "
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm bg-info"
                              v-if="
                                $store.getters.can(
                                  'hse.solicitudesVehiculos.show'
                                )
                              "
                              @click="edit(solicitud, 2)"
                            >
                              <i class="fas fa-eye"></i>
                            </button>
                            <button
                              v-if="
                                solicitud.estado == 4 &&
                                  $store.getters.can(
                                    'hse.solicitudesVehiculos.verTarjeton'
                                  )
                              "
                              type="button"
                              class="btn btn-sm btn-success"
                              @click="verTarjeton(solicitud.id)"
                            >
                              <i class="fas fa-wallet"></i>
                            </button>
                            <!-- <button type="button" class="btn btn-sm btn-danger">
                              <i class="fas fa-trash-alt"></i>
                            </button> -->
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-footer">
                  <div class="float-left" v-if="solicitudesVehiculos.total">
                    Mostrando del <b>{{ solicitudesVehiculos.from }}</b> al
                    <b>{{ solicitudesVehiculos.to }}</b> de un total:
                    <b>{{ solicitudesVehiculos.total }}</b> Registros
                  </div>
                  <div class="float-left" v-else>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </div>
                  <pagination
                    :data="solicitudesVehiculos"
                    @pagination-change-page="getIndex"
                    :limit="5"
                    class="float-right m-0"
                  ></pagination>
                </div>
              </div>
            </div>
            <SolicitudesVehiculosExport ref="SolicitudesVehiculosExport" />
          </section>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import moment from "moment";
import SolicitudesVehiculosExport from "./SolicitudesVehiculosExport";
export default {
  name: "SolicitudesVehiculosIndex",
  components: { vSelect, pagination, Loading, SolicitudesVehiculosExport },
  data() {
    return {
      cargando: false,
      filters: {
        solicitud_id: null,
        placa: null,
        email: null,
        modelo: null,
        estado: null,
        empresa_id: null,
        activo_id: null,
        clase_vehiculo_id: null,
        marca_id: null,
        area: null,
        operacion_id: null,
        estado_inspeccion: null,
      },
      solicitud: null,
      operacion: null,
      empresa: null,
      activo: null,
      clase_vehiculo: null,
      marca: null,
      area: null,
      solicitudesVehiculos: {},
      listsForms: {
        conductores: [],
        empresas: [],
        activos: [],
        tipos_contratos: [],
        categorias_licencias: [],
        clases_vehiculos: [],
        tipo_documentos: [],
        marcas: [],
        areas: [],
        estados: [],
        sitios: [],
        tarjetones: [],
        estadosInsp: [],
      },
      modal: {
        sitio_id: null,
        sitio: [],
        tarjetones: [],
        tarjeton_id: null,
        solicitud_id: null,
        fecha: null,
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("api/hse/HseSolicitudesVehiculos?page=" + page, {
          params: this.filters,
        })
        .then((response) => {
          this.cargando = false;
          this.solicitudesVehiculos = response.data;
        });
    },

    back() {
      this.clearFilters();
      this.getIndex();
    },

    clearFilters() {
      this.filters = {
        solicitud_id: null,
        placa: null,
        email: null,
        modelo: null,
        estado: null,
        empresa_id: null,
        activo_id: null,
        clase_vehiculo_id: null,
        marca_id: null,
        area: null,
        operacion_id: null,
        estado_inspeccion: null,
      };
      this.area = null;
      this.empresa = null;
      this.activo = null;
      this.clase_vehiculo = null;
      this.marca = null;
      this.operacion = null;
    },

    //Obtener Listas
    getEmpresas() {
      axios.get("/api/lista/164").then((response) => {
        this.listsForms.empresas = response.data;
      });
    },

    getActivos() {
      axios.get("/api/lista/117").then((response) => {
        this.listsForms.activos = response.data;
      });
    },

    getOperacionVehiculos() {
      axios.get("/api/lista/167").then((response) => {
        this.listsForms.operacion_vehiculos = response.data;
      });
    },

    getClasesVehiculos() {
      axios.get("/api/lista/163").then((response) => {
        this.listsForms.clases_vehiculos = response.data;
      });
    },

    getMarcas() {
      axios.get("/api/lista/12").then((response) => {
        this.listsForms.marcas = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/175").then((response) => {
        this.listsForms.estados = response.data;
      });
    },

    getEstadosInsp() {
      axios.get("/api/lista/38").then((response) => {
        this.listsForms.estadosInsp = response.data;
      });
    },

    getTargetones() {
      axios.get("/api/hse/HseTarjetonesLista").then((response) => {
        this.listsForms.tarjetones = response.data;
      });
    },

    getAreas() {
      axios.get("/api/lista/99").then((response) => {
        this.listsForms.areas = response.data;
      });
    },

    //Seleccionar solo ids
    selectEmpresa() {
      this.filters.empresa_id = null;
      if (this.empresa) {
        this.filters.empresa_id = this.empresa.numeracion;
      }
      this.getIndex();
    },

    selectActivo() {
      this.filters.activo_id = null;
      if (this.activo) {
        this.filters.activo_id = this.activo.numeracion;
      }
      this.getIndex();
    },

    selectClaseVehiculo() {
      this.filters.clase_vehiculo_id = null;
      if (this.clase_vehiculo) {
        this.filters.clase_vehiculo_id = this.clase_vehiculo.numeracion;
      }
      this.getIndex();
    },

    selectArea() {
      this.filters.area = null;
      if (this.area) {
        this.filters.area = this.area.numeracion;
      }
      this.getIndex();
    },

    selectMarca() {
      this.filters.marca_id = null;
      if (this.marca) {
        this.filters.marca_id = this.marca.numeracion;
      }
      this.getIndex();
    },

    selectOperacion() {
      this.filters.operacion_id = null;
      if (this.operacion) {
        this.filters.operacion_id = this.operacion.numeracion;
      }
      this.getIndex();
    },

    async generarTarjeton(solicitud) {
      let datos = {
        solicitud_vehiculo_id: solicitud.id,
        fecha: solicitud.fecha_fin_tarjeton,
        placa: solicitud.placa,
        vehiculo_id: solicitud.vehiculo_id,
      };
      let mensaje = "Los cambios no se pueden revertir!";
      if (!solicitud.inspeccion_id) {
        let data_solicitud = {
          id: solicitud.id,
          vehiculo_id: solicitud.vehiculo_id,
          placa: solicitud.placa,
        };
        //Se busca una inspección vigente para la solicitud y se asocia
        await axios
          .post(
            "/api/hse/HseSolicitudesVehiculos/asociarInspeccion",
            data_solicitud
          )
          .then((response) => {
            if (response.data.inspeccion_id) {
              datos = {
                solicitud_vehiculo_id: solicitud.id,
                fecha: response.data.fecha_fin_tarjeton,
                placa: solicitud.placa,
                vehiculo_id: solicitud.vehiculo_id,
              };
              mensaje =
                "Se asignó la inspección # " +
                response.data.inspeccion_id +
                " para esta solicitud, Los cambios no se pueden revertir!";
            }
          });
      }
      await this.$swal({
        title: "Esta seguro de asignarle un tarjeton a esta solicitud?",
        text: mensaje,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Asignar!",
      }).then((result) => {
        if (result.value) {
          this.cargando = true;
          axios
            .put("/api/hse/HseTarjeton", datos)
            .then((response) => {
              this.cargando = false;
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se asigno el tarjetón exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            })
            .catch((e) => {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.cargando = false;
            });
        }
      });
    },

    edit(solicitud, acto) {
      return this.$router.push({
        name: "/Hse/SolicitudVehiculoForm",
        params: {
          id: solicitud.id,
          acto: acto,
        },
      });
    },

    verTarjeton(solicitud) {
      this.cargando = true;
      axios({
        method: "get",
        url: "/api/hse/HseTarjeton/pdf/" + solicitud,
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    inspeccion(solicitud) {
      let data = {
        bloquear: true,
        empresa_hse: solicitud.empresa,
        placa_vehiculo: solicitud.placa,
        inspeccion_id: solicitud.inspeccion_id,
      };
      return this.$router.push({
        name: "/Hse/InspeccionesForm",
        params: {
          data: data,
        },
      });
    },
  },

  beforeMount() {
    this.getAreas();
    this.getEmpresas();
    this.getActivos();
    this.getOperacionVehiculos();
    this.getClasesVehiculos();
    this.getMarcas();
    this.getEstados();
    this.getTargetones();
    this.getEstadosInsp();
  },

  mounted() {
    this.getIndex();
  },
};
</script>

<style>
.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
